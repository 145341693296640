<script setup lang="ts">
import { useThemeStore } from '@/stores/theme'
import { IconMenu2 } from '@tabler/icons-vue'
const customizer = useThemeStore()
</script>

<template>
  <v-app-bar elevation="10" height="70">
    <div class="v-toolbar__content">
      <v-btn
        class="hidden-md-and-down custom-hover-primary"
        icon
        color="primary"
        variant="text"
        @click.stop="customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar)"
      >
        <IconMenu2 size="20" stroke-width="1.5" />
      </v-btn>

      <v-btn
        class="hidden-lg-and-up"
        icon
        variant="flat"
        size="small"
        @click.stop="customizer.SET_SIDEBAR_DRAWER"
      >
        <IconMenu2 size="20" stroke-width="1.5" />
      </v-btn>

      <v-spacer />

      <div class="ml-3 mr-sm-0 mr-3 pr-3">
        <ProfileDD />
      </div>
    </div>
  </v-app-bar>
</template>
